@import "../../mixins/mixins.module.scss";
.team {
  &__title {
    @include section-title;
    @media(max-width:390px) {
      font-size: 28px;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    padding-top: 40px;
    flex-wrap: wrap;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform 0.5s ease-in;
    padding: 26px;
    border-radius: 16px;
    background-color: #461a45;
    // width:25%;
    @media (max-width: 720px) {
      width: 25%;
    }
    &:hover {
      transform: translateY(-5px);
      filter: drop-shadow(2px 2px 2px blackтз);
    }
    &-image {
      width: 250px;
      height: 300px;
      @media (max-width: 720px) {
        width: 150px;
        height: 200px;
      }
      @media(max-width:490px) {
        width: 90px;
        height: 100px;
      }
    }
    &-name {
      padding-top: 15px;
      font-size: 28px;
      color: #fff;
      @media (max-width: 720px) {
        font-size: 16px;
        padding-top: 5px;
      }
    }
    &-position {
      padding-top: 20px;

      font-size: 24px;
      color: #fff;
      @media (max-width: 720px) {
        padding-top: 5px;
        font-size: 12px;
      }
    }
  }
}
