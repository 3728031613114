@import '../../mixins/mixins.module.scss';
.partners {
    
    background-image: url(../../images/capy.svg);
    @media(max-width:1430px) {
        background-size: 35%;
    }
    &__title {
    @include section-title;
    @media(max-width:1050px) {
        font-size: 32px;
    }
    }

    &__items {
        padding-top: 50px;
        display: grid;
        grid-template-columns: repeat(3, min-content);
        justify-content: center;
        align-items: center;
        gap:50px;
        @media(max-width:430px) {
            gap:30px;
        }
    }

    &__container {


        &-img {
            width: 300px;
            @media(max-width:1050px) {
                width: 200px;
            }
            @media(max-width:730px) {
                width: 100px;
            }
            @media(max-width:430px) {
                width: 75px;
            }
        }
    }

    
}