@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,700;1,700&display=swap');
body {
    font-weight: 700;
    font-family: 'IBM Plex Mono', monospace;
    background-color:rgb(100 90 141);
}
ul {
    padding: 0;
}

ul li {
    list-style: none;
    padding: 0;
}
.App-round {
    position: fixed;
    margin-left: auto;
    border-radius: 50%;
    overflow: hidden;
    clip-path: circle(50%);
    background: #fbff07;
    transition: background-color .3s;
    pointer-events: auto;
    width: 90px;
    height: 90px;
    right: 5%;
    bottom:5%;
    will-change: transform;
    transition: transform 1s ease-out;
    padding: 5px;
    z-index: 100;
}
.App-round__text {
    will-change: transform;
    transition: transform 1s ease-out;
}
.App-capy {
    position: absolute;
}
.hero {
    height:120vh;
      background-image: url(./images/capy.jpg);
      background-size: cover;
      background-position: center center;
    
}
.title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 214px;
    color: rgb(100 90 141);
    text-align: center;
  
    filter: blur(0px);
    
    
    /* filter: drop-shadow(5px 5px 1px white); */
}


.subtitle {
    font-size: 121px;
    color: #fff;
    font-weight: 700;
    display: flex;
    margin: 0 auto;
    text-align: center;
    animation: text 4s ease-in-out infinite;
    
    
}
@keyframes text {
    0% {
        filter: drop-shadow(2px 4px 12px rgba(255,231,0,1));
    }
    100% {
        filter: drop-shadow(2px 2px 2px transparent)
    }
}



.capy__container {
    display: flex;
    justify-content: center;
    
    margin: 0 auto;
    padding: 150px;
}

.capy__container-coin {
    
    width: 500px;
    align-self: center;
    margin-right: 50px;
    animation: rotatecoin 8s linear infinite;
    filter: drop-shadow(0px 0px 30px #fff);
}
.capy__container-text {
    font-size: 48px;
    color: #fff;
    text-align: center;
    align-self: center;
}

@keyframes rotatecoin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background:red;
    transform-origin: 0%;
  }

@media (max-width:1480px) {
    .subtitle {
        font-size: 100px;
    }
    .capy__container {
        padding: 100px;
    }
}
@media (max-width:1380px) {
    .capy__container-coin{
        width: 350px;
    }
    .capy__container-text {
        font-size: 36px;
    }
}
@media (max-width:1230px) {
    .subtitle {
        font-size: 75px;
    }
    .title {
        font-size: 160px;
    }
}
@media (max-width:1100px) {
    .capy__container {
        flex-direction: column;
    }
    .capy__container-coin {
        margin-right: 0;
    }
}
@media (max-width:920px) {
    .subtitle {
        font-size: 50px;
        padding-top: 30px;
    }
    .title {
        font-size: 120px;
    }
}
@media(max-width:650px) {
    .title {
        font-size: 70px;
    }
    .subtitle {
        font-size: 35px;
        padding: 30px;
        text-align: center;
    }
    .capy__container {
        padding: 20px;
    }
}
@media (max-width:480px) {
    .subtitle {
        font-size: 26px;
    }
    .hero {
     
        background-position-y: top;
        background-size: 300%;
        background-repeat: no-repeat;
        height: 100vh;
    }
    .capy__container-coin {
        width: 280px;
    }
    .capy__container-text {
        font-size: 26px;
    }
}

