.index {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media(max-width:920px) {
    flex-direction: column-reverse;
  }
  &__img {
    width: 600px;
    @media (max-width:1220px) {
      width: 400px;
    }
    @media(max-width:480px) {
      width: 290px;
    }
  }
  &__container {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-self: end;
    &-arrow {
      width: 50px;
      height: 50px;
      fill: #3cff01;
      transform: rotate(45deg);
      animation: arrowUp 3s linear infinite;
    }
    @media (max-width:920px) {
      align-self: center;
    }
  }

  &__text {
    font-size: 48px;
    color: #fff;
    position: relative;
    max-width: 600px;
    align-self: end;

    width: calc(100%);
    background-image: linear-gradient(transparent calc(100% - 10px), #3cff01 10px);
    background-repeat: no-repeat;

    animation: underline 7s infinite;
    @media(max-width:1220px) {
      font-size: 40px;
    }
    @media(max-width:480px) {
      font-size: 24px;
    }
  }
}
@keyframes arrowUp {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(300px, -200px) rotate(45deg);
    @media(max-width:420px) {
      transform: translate(200px, -150px) rotate(45deg);
    }
  }
}
@keyframes underline {
    0% {
        background-size: 0% 100%;
    }
    100% {
        background-size: 100% 100%;
    }
}